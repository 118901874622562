import { Address } from '@entities/shared/address.entity';
import { Entity, EntityBuilder, Type } from '@jobilla/entity';
import { UserRole } from './user-role.enum';
import { NonEnumerable } from '@core/decorators/non-enumerable.decorator';

export class User extends Entity {
  public id: number = null;
  public firstName: string = null;
  public lastName: string = null;
  public birthName: string = null;
  public dateOfBirth: string = null;
  public email: string = null;
  public roleId: UserRole = null;
  public companyId: number = null;
  public companyName: string = null;
  public isFlatFee: boolean = false;
  public flatFeeBase: number = 0;
  public isSubscribed: boolean = false;
  public isEmailNotified: boolean = false;
  public isFinanceManager: boolean = false;
  public isMfaEnabled: boolean = false;
  public linkedinUrl: string = null;
  public externalId: string = null;
  public insuranceExpiry: Date = null;
  public assignment: string = null;
  public phone: string = null;
  public areaCode: string = null;
  public region: string = null;
  public clientId: number = null;
  public clientName: string = null;
  public termsAgreed: string = null;
  public createdBy: number | null = null;
  public createdAt: Date | null = null;
  public updatedBy: number = null;
  public updatedAt: Date = null;
  public subscribedAt: Date = null;
  public employeeCode: string = null;
  public lastNotificationCheckedAt: string = null;
  public isDeactivated: boolean = false;

  @Type(Address)
  public location: Address = new Address();

  @NonEnumerable
  private _initials: string = null;

  public constructor(initData: Partial<User> = {}) {
    super();
    EntityBuilder.fill(this, initData as any);
  }

  public get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  public get initials(): string {
    if (this._initials) {
      return this._initials;
    }
    this._initials = this.firstName?.charAt(0).toUpperCase() + this.lastName?.charAt(0).toUpperCase();
    return this._initials;
  }

  public get isAdmin(): boolean {
    return this.roleId === UserRole.Admin;
  }

  public get isAdminOrGreater(): boolean {
    return this.roleId === UserRole.Admin || this.roleId === UserRole.SuperAdmin;
  }

  public get isManager(): boolean {
    return this.roleId === UserRole.Manager;
  }

  public get isSuperAdmin(): boolean {
    return this.roleId === UserRole.SuperAdmin;
  }

  public get isApprover(): boolean {
    return this.roleId === UserRole.Approver;
  }

  public get isWorker(): boolean {
    return this.roleId === UserRole.Worker;
  }

  public get isManagerOrGreater(): boolean {
    return this.isManager || this.isAdmin || this.isSuperAdmin;
  }
}
